import DailyIframe, { DailyCallOptions } from '@daily-co/daily-js';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Grid, TextField, Box } from '@mui/material';

import { getVideoURL } from '../../lib/api/videoURL';
import { AppointmentParticipantType } from '../../lib/type.ts';
import RDLogoDark from '../../components/Common/RDLogoDark';
import DocumentsDrawer from '../../components/Documents/patientDocumentsDrawer';

let callFrame: any;

const useStyles = makeStyles(() => ({
	text: {
		color: 'white',
		padding: '16px',
		textAlign: 'center',
	},
	container: {
		backgroundColor: '#002B87',
		margin: 0,
		padding: 0,
		width: '100vw',
		height: '100vh',
		overflow: 'hidden',
		position: 'fixed',
		top: 0,
		left: 0,
	},
	leaveButton: {
		padding: '20px',
		position: 'fixed',
		bottom: '0px',
	},
	logo: {
		padding: '10% 40px 40px 40px',
	},
	input: {
		backgroundColor: 'white',
		color: 'black',
		border: 'white',
		outline: 'white',
	},
}));

export default function VideoPage() {
	const classes = useStyles();
	const [errorText, setErrorText] = useState('');
	const [helperText, setHelperText] = useState('');
	const [uploadDrawer, setUploadDrawer] = useState(false);
	const [appointmentId, setAppointmentId] = useState('');
	const [participantType, setParticipantType] = useState<AppointmentParticipantType>();
	const [error, setError] = useState(false);
	const [code, setCode] = useState('');
	const [step, setStep] = useState(0);

	const isParticipantPatient = participantType === AppointmentParticipantType.PATIENT;

	async function startVisit(evt: any) {
		evt?.preventDefault?.();
		evt?.stopPropagation?.();

		if (code.length != 6) {
			setHelperText('Please enter your access code from invition.');
			setError(true);
			return;
		}

		const urlParams = new URLSearchParams(window.location.search);
		const id = urlParams.get('id') as string;

		try {
			const { data } = (await getVideoURL(id, code)) as any;

			setAppointmentId(data.appointmentId);

			setParticipantType(data.participantType);

			const config: DailyCallOptions = {
				showLeaveButton: true,
				iframeStyle: {
					position: 'fixed',
					margin: 'auto',
					width: '100%',
					height: '85%',
					bottom: '15%',
					zIndex: '10',
					border: '0',
				},
			};

			callFrame = DailyIframe.createFrame(config);

			const url = `${import.meta.env.VITE_DAILY_BASE_URL}/${data.roomId}`;

			callFrame.join({ url });

			setStep(1);
		} catch (error) {
			setErrorText(
				'Your video session is either invalid or could not be started yet.  Check back within five minutes of your appointment time.',
			);
		}
	}

	function handleCodeChange(evt: any) {
		setCode(evt.target.value);
		setErrorText('');
		setHelperText('');
	}

	return (
		<Box display="grid" className={classes.container}>
			<Grid container spacing={1} direction="column" justifyContent="space-around" alignItems="center">
				<div className={classes.logo}>
					<RDLogoDark />
				</div>

				{step === 0 && (
					<form noValidate onSubmit={startVisit}>
						<Grid container spacing={1} direction="column" justifyContent="space-around" alignItems="center">
							<div className={classes.text}>Please enter your access code to start visit</div>
							<div className={classes.text}>{errorText}</div>
							<TextField
								className={classes.input}
								placeholder="Access Code"
								variant="outlined"
								style={{ margin: '16px' }}
								onChange={handleCodeChange}
								value={code}
								helperText={helperText}
								error={error}
							/>
							<Button
								onClick={startVisit}
								variant="contained"
								type="submit"
								style={{
									backgroundColor: '#04AFDE',
									color: 'black',
									margin: '60px',
								}}
							>
								Start Visit
							</Button>
						</Grid>
					</form>
				)}

				{step === 1 && (
					<Grid container spacing={1} direction="column" justifyContent="flex-end" alignItems="center">
						<div className={classes.leaveButton}>
							<Grid container direction="row" xs={12}>
								<Grid item spacing={1} xs={6}>
									<Button
										onClick={() => {
											if (callFrame) {
												callFrame.destroy();
											}
											setStep(2);
										}}
										variant="contained"
										style={{
											backgroundColor: '#04AFDE',
											color: 'black',
											margin: '16px',
										}}
									>
										Leave
									</Button>
								</Grid>
								{isParticipantPatient && (
									<Grid item spacing={1} xs={6}>
										<Button
											onClick={() => setUploadDrawer(!uploadDrawer)}
											variant="contained"
											style={{
												backgroundColor: '#04AFDE',
												color: 'black',
												margin: '16px',
											}}
										>
											Files
										</Button>
									</Grid>
								)}
								<DocumentsDrawer
									appointmentId={appointmentId}
									isParticipantPatient={isParticipantPatient}
									uploadDrawer={uploadDrawer}
									setUploadDrawer={setUploadDrawer}
								/>
							</Grid>
						</div>
					</Grid>
				)}

				{step === 2 && (
					<Grid container spacing={1} direction="column" justifyContent="space-around" alignItems="center">
						<div className={classes.text}> Thank you for using Rocket Doctor! </div>
					</Grid>
				)}
			</Grid>
		</Box>
	);
}
