import axios from 'axios';
import { useAuthStore } from '../context/useAuthStore.ts';

export const authenticatedInstance = axios.create({
	withCredentials: true,
	baseURL: `${import.meta.env.VITE_APIV2}`,
});

authenticatedInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response?.status === 401) {
			useAuthStore.getState().logout();
		}

		throw new Error(error);
	},
);

export const get = async (url: string, config?: any) => {
	console.info({ url, config }, 'api/helper/get');

	try {
		const { data } = await authenticatedInstance.get(`${url}`, config);

		console.info({ url, config, data }, 'api/helper/get');

		return data;
	} catch (error: any) {
		console.error(`api/helper/get`, { url, config }, error);
		throw new Error(error);
	}
};

export const post = async (url: string, body: any = {}, _params?: any) => {
	try {
		const { data } = await authenticatedInstance.post(`${url}`, body, { params: _params });
		return data;
	} catch (error: any) {
		console.error(`error on get ${url}: `, error);
		throw new Error(error);
	}
};

export const put = async (url: string, body: any = {}, _params?: any) => {
	try {
		const { data } = await authenticatedInstance.put(`${url}`, body, { params: _params });
		return data;
	} catch (error: any) {
		console.error(`error on get ${url}: `, error);
		throw new Error(error);
	}
};
