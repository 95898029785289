import * as Sentry from '@sentry/react';
import { useQuery } from 'react-query';

import { Pharmacy } from '../../../lib/type.ts';
import { authenticatedInstance } from '../../../lib/api/_helper.ts';

export function useGetPharmacyList(postalCode: string = '', Cookie = {}) {
	const limit = 50;

	const formatedPostalCode = postalCode?.replace(/_/g, '').replace(/ /g, '');

	const endpoint = `${import.meta.env.VITE_APIV2}/apiv2/pt/pharmacies/locations?limit=${limit}&postal_code=${formatedPostalCode}`;

	const config = {
		baseURL: `${import.meta.env.VITE_APIV2}`,
		headers: { Cookie, cache: 'no-cache' },
	};

	Sentry.addBreadcrumb({
		level: 'debug',
		type: 'useGetPharmacyList',
		message: 'useGetPharmacyList',
		data: {
			postalCode,
			cookie: Cookie,
			config,
			endpoint,
		},
	});

	const { data: pharmacies } = useQuery<Pharmacy[]>(
		['getPharmacies', { formatedPostalCode, limit }],
		async () => await authenticatedInstance.get(endpoint).then(({ data }) => data),
		{
			refetchInterval: false,
			refetchOnReconnect: false,
			refetchIntervalInBackground: false,
			enabled: formatedPostalCode?.length > 5,
		},
	);

	Sentry.addBreadcrumb({
		level: 'debug',
		type: 'useGetPharmacyList',
		message: 'useGetPharmacyList',
		data: {
			pharmacies,
		},
	});

	console.log('useGetPharmacyList:<<=', { pharmacies, count: pharmacies?.length, formatedPostalCode });

	return pharmacies ?? [];
}
